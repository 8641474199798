@import "utils/variables";

.beanfield-button {
  border: none;
  background: $primary_green;
  color: $dark_grey;
  border-radius: 1.375rem;
  padding: 0.5rem 1.5rem;
  font-size: 1rem;

  &:hover {
    cursor: pointer;
    background-color: $btn_hover;
  }

  &.disabled {
    background-color: $btn_grey;
    color: $btn_disabled;
    cursor: not-allowed;

    //TODO (maybe need to comment this out)
    border-radius: 1.375rem;
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
  }
  &.selected {
    background: $btn_black;
    border: 2px solid $btn_black;
    color: $white;
    border-radius: 1.375rem;
    padding: 0.5rem 1.875rem;
    font-size: 1rem;
  }
}

.campaign-button {
  border: none;
  background: $black;
  color: $white;
  border-radius: 1.375rem;
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  font-weight: 700;

  &:hover {
    cursor: pointer;
    background-color: #58595B;
  }
}

.beanfield-button--hollow {
  background: transparent;
  border: 2px solid $btn_black;
  color: $btn_black;
  border-radius: 1.375rem;
  padding: 0.5rem 1.875rem;
  font-size: 1rem;

  &.green {
    border: 2px solid $secondary_forest;
    color: $secondary_forest;
  }

  &:hover {
    cursor: pointer;
  }

  &.disabled {
    background-color: transparent;
    color: $btn_disabled;
    border: 1px solid $btn_black;
    cursor: not-allowed;
    border-radius: 1.375rem;
    padding: 0.5rem 1.875rem;
    font-size: 1rem;
    opacity: 0.25;
  }

  &.selected {
    background: $btn_black;
    border: 2px solid $btn_black;
    color: $white;
    border-radius: 1.375rem;
    padding: 0.5rem 1.875rem;
    font-size: 1rem;
  }
}
