@import "utils/variables";

.page-link-container {
  display: flex;
  color: $secondary_forest;
  position: relative;
  align-items: center;
  justify-content: start;

  &:hover {
    cursor: pointer;
  }

  a {
    font-size: 1rem;
    line-height: 1.5rem;
    color: $secondary_forest;
  }

  div {
    position: relative;
    margin-left: 0.25rem;
  }
}

.page-link-container.bordered {
  border: 0.125rem solid $secondary_forest;
  padding: 0.125rem 1.25rem;
  border-radius: 1.25rem;
}
