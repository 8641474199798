@import "utils/variables";
// LEFT BLOCK
.orderPage_left_block_container {
  display: flex;
  flex-direction: column;
  flex: 0.7;
  width: 100%;

  @media (max-width: 1254px) {
    display: flex;
    flex: 1;
    width: 100%;
    padding-bottom: 3rem;
  }

  .orderPage_continue_btn_container {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-top: 2rem;

    .orderPage_continue_btn {
      border: none;
      background-color: #99cc00;
      padding: 0.5rem 1.5rem;
      border-radius: 1rem;
      font-weight: 700;

      &:hover {
        cursor: pointer;
        background-color: #8cbe00;
      }
    }
  }

  .orderPage_left_fibre_internet_on_container {
    display: flex;
    flex-direction: column;
    &:last-child {
      .internet-package-separator {
        display: none;
      }
    }

    .internet-package-separator {
      margin: 0 3.25rem 0 3.25rem;
      border-top: 1px solid #ACACAC;
    }

    .horizontal_line {
      border-bottom: 1px solid gray;
      margin: 0 3.2rem;
      opacity: 0.35;

      @media screen and (max-width: 48rem) {
        margin: 0 1.8rem;
      }
    }
  }

  .orderPage_left_fibre_internet_promo {
    display: flex;
    flex-direction: column;
    background-color: $order_promo_background;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem 10rem;

    @media screen and (max-width: 1254px) {
      padding: 1rem 10rem;
    }
    @media screen and (max-width: 1000px) {
      padding: 1rem 5rem;
    }
    @media screen and (max-width: 48rem) {
      padding: 1rem 2rem;
      P {
        font-family: "WalsheimRegular";
        font-size: 1rem !important;
      }
      h3 {
        font-size: 1rem !important;
      }
    }

    p {
      margin-top: 0.35rem;
    }
    h3 {
      font-size: 20px;
    }
  }
  .orderPage_left_inner_container {
    display: flex;
    flex-direction: row;
    margin-top: 1.25rem;
    padding: 0 2.5rem 1.5rem 2.25rem;

    &.extra_padding_radioBtn {
      padding: 0 3.5rem 1.5rem 2.5rem;
    }

    p {
      margin-top: 0.5rem;
      width: 75%;
    }

    @media screen and (max-width: 48rem) {
      padding: 0 1rem 1.5rem 0.75rem;

      input {
        margin-right: 0.5rem;
      }
      p {
        width: 100%;
      }

      &.extra_padding_radioBtn {
        padding: 0 1rem 1.5rem 1rem;
      }
    }

    .orderPage_left_inner_wrapper {
      margin-top: 0.1rem;
      margin-left: 1rem;
      width: 100%;
      position: relative;

      @media screen and (max-width: 48rem) {
        margin-left: 0;
        margin-top: 0.0625rem;
        font-family: "WalsheimRegular";
        font-size: 1rem;
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        margin: 1.25rem 0 0;
        padding: 0;

        @media (max-width: 48rem) {
          flex-direction: column;
        }

        li {
          display: flex;
          width: 50%;
          flex-direction: row;
          align-items: center;
          margin: 0.125rem 0;
          padding: 0;

          @media (max-width: 48rem) {
            width: 100%;
          }

          p {
            &:nth-child(2) {
              margin: 0 0 0.25rem 0.5rem;
              font-family: "WalsheimBold";
              width: fit-content;
            }
          }

          p.feature_wrapper {
            display: flex;
            align-items: center;
            width: 100%;
          }

          h4 {
            margin-bottom: 0.25rem;
            margin-left: 0.35rem;
            font-weight: 700;
          }

          @media screen and (max-width: 48rem) {
            align-items: flex-start;
            h4 {
              max-width: 85%;
            }
          }
        }
      }
      a {
        margin: 1rem 0 0 0;
        text-decoration: none;
        color: $secondary_forest;
        font-weight: 700;
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
      p {
        margin-bottom: 0.6rem;
        &.not-black-friday-description {
          @media (min-width: 769px) {
            display: none;
          }
        }
      }

      .inner_wrapper_mobile {
        display: flex;

        @media screen and (max-width: 48rem) {
          margin-left: 1rem;
        }

        .mobile_starter_package {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-left: 1rem;

          @media screen and (max-width: 48rem) {
            margin-left: 0rem;
          }
        }
      }

      .orderPage_left_inner_title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        p {
          width: 100%;
        }

        div:first-child {
          padding-right: 0.5rem;
        }

        @media screen and (max-width: 48rem) {
          h3 {
            font-size: 1.25rem !important;
          }
          p {
            margin-top: 0;
          }
        }

        h3 {
          font-size: 24px;
        }

        .black-friday-promo-title-description {
          padding-right: 1rem;
          h3 {
            margin-bottom: 0.5rem;
          }
          p {
            @media (max-width: 768px) {
              display: none;
            }
          }
        }

        .black-friday-promo-price {
          position: relative;
          .green-strikethrough {
            text-decoration: line-through #000;
            color: #99CC00;
            margin-bottom: 0.25rem;
            @media (max-width: 768px) {
              position: absolute;
              font-size: 1rem !important;
              color: #00663e;
              text-align: right;
              top: -1rem;
              right: 0;
            }
          }
        }

        .orderPage_left_inner_title_credit {
          color: $autopay_grey;
          font-size: 0.75rem;
          font-weight: 400;
          line-height: 1rem;
          word-wrap: break-word;
          margin-top: 0.5rem;
          div {
            line-height: 1rem;
            padding: 0;
          }
        }
      }
      .orderPage_left_inner_description {
        position: relative;

        @media (max-width: 768px) {
          top: unset;
        }
      }
      // THEME PACKS
      .orderPage_left_inner_misc_theme_pack_container {
        margin-top: 1rem;

        .orderPage_left_inner_misc_alacarte_container {
          max-height: 15rem;
          overflow-y: scroll;
          padding: 0 1rem;
          margin-right: 0.5rem;

          &::-webkit-scrollbar {
            width: 0.5rem;
            left: -105px;
          }
          &::-webkit-scrollbar-track {
            background: #f1f1f1;
          }
          &::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 0.5rem;
          }

          .channel_container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-left: 0.5rem;

            @media screen and (max-width: 48rem) {
              margin: 0;
            }
            .alacarte_list_container {
              display: flex;
              width: 100%;

              p {
                margin-left: 1rem;
              }

              @media screen and (max-width: 48rem) {
                p {
                  margin-left: 0;
                }
              }
            }
            .price_container {
              width: 55px;
            }
            .description_container {
              display: flex;
              flex: 1;
              padding: 0 1.5rem 0 1rem;

              @media screen and (max-width: 48rem) {
                padding: 0 1rem 0 0.5rem;
              }

              p {
                flex: 1.15;
                margin: 0;
                padding: 0;
              }
              a {
                flex: 0.75;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin: 0;
                padding: 0;

                @media screen and (max-width: 48rem) {
                  justify-content: flex-start;
                }
              }

              @media screen and (max-width: 48rem) {
                display: flex;
                flex-direction: column;
                margin-top: 1.25rem;
                flex: 1;

                p {
                  flex: 1;
                  margin: 0;
                  padding: 0;
                }
                a {
                  text-align: left !important;
                  margin: 0;
                  padding: 0;
                }
              }
            }
          }
        }
      }
      // SET-TOP BOXES
      .orderPage_left_inner_misc_container {
        border: 2px solid $btn_black;
        border-radius: 0.5rem;
        padding: 1rem 1rem 0.5rem 1rem;
        margin-top: 1rem;

        .orderPage_left_inner_misc_additional_boxes_container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .additional_boxes_btn_container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            min-width: 5rem;

            &:hover {
              cursor: pointer;
            }

            .additional_boxes_btn {
              display: flex;
              justify-content: center;
              border-radius: 50%;
              height: 24px;
              width: 24px;
              background-color: #325a32;
              align-items: center;
              color: white;
              font-size: 1.35rem;
            }
          }
        }
      }

      .promo-container {
        display: flex;
        justify-content: center;
        position: relative;
        background-image: url('/assets/backgrounds/bg_bf_promo.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        height: 8.125rem;
        border-radius: 2rem;
        margin: 2rem 0 1rem;
        z-index: 0;
        @media (max-width: 768px) {
          background-image: url('/assets/backgrounds/bg_left_bf_mobile.png'), url('/assets/backgrounds/bg_bf_promo.png');
          background-position: left 88%, center;
          background-size: auto, cover;
        }
        .promo-image-container {
          position: absolute;
          bottom: -6px;
          z-index: 2;
          @media (max-width: 768px) {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            bottom: unset;
          }
          img {
            max-height: 10rem;
          }
        }

        .offset-border {
          border-radius: 2rem;
          width: 99.8%;
          height: 99.8%;
          position: absolute;
          bottom: -3px;
          right: -2px;
          z-index: 1;
          border: 1px solid #000;
        }
      }
    }
  }
}
