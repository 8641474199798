@import "utils/variables";

.yourAddress_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
  background-color: $background_gray;
  border-radius: 1rem;

  @media screen and (min-width: 46.875rem) and (max-width: 78.125rem) {
    flex: 1;
    margin-top: 3.5rem;
  }
  &:not(:has(+ .unit-has-service-container)) {
    margin: 0 0 1rem;
  }

  span {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media screen and (max-width: 48rem) {
      align-items: flex-start;
      flex-direction: column;
    }
  }
  p {
    font-size: 16px;
    margin-left: 0.25rem;

    @media screen and (max-width: 48rem) {
      margin-left: 0;
    }
  }
  h4 {
    font-size: 16px;
    margin: 0;
    padding: 0;
  }
  button {
    border: none;
    font-size: 1rem;
    color: $secondary_forest;
    background-color: inherit;
    &:hover {
      cursor: pointer;
    }
  }
}
.unit-has-service-container {
  display: flex;
  color: $warning;
  background-color: $warning_background;
  padding: 0.625rem 1rem;
  border-radius: 1rem;
  margin: 1rem 0 1rem;
  align-items: center;

  .left-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .right-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    p {
      margin-left: 1.5rem;
    }

    span {
      color: $secondary_forest;
      min-width: 200px;
      &:hover {
        cursor: pointer;
      }
      text-align: right;
    }
  }

  @media screen and (max-width: 48rem) {
    padding: 1.5rem 2rem;

    .left-content {
      display: flex;
      flex: 1;
      height: 100%;
      justify-content: center;
      align-items: center;
      margin-left: 0;
    }
    .right-content {
      display: flex;
      flex-direction: column;
      margin-left: 1rem;

      p {
        margin-left: 0;
        width: 100%;
      }
      span {
        margin-top: 0.35rem;
        width: 100%;
        text-align: left;
      }
    }
  }
}

