@import "utils/variables";

.internet-banner-background {
  background: linear-gradient(99deg, #94D4D1 0.33%, #D0E88A 100%);
  min-height: 38rem;

  &.black-friday-promo {
    background: linear-gradient(99deg, #99CC01 0.33%, #FFF 99.35%);

    @media (max-width: 1024px) {
      min-height: unset;
    }
  }

  @media (max-width: 768px) {
    min-height: unset;
  }
}

.internet-banner-container {
  display: flex;
  padding: 2.5rem;

  @media (min-width: 769px) and (max-width: 1024px){
    flex-direction: column;
    padding: 2.5rem 2.5rem 4.5rem;
  }

  @media (max-width: 768px) {
    height: 34.375rem;
    padding: 0;
    justify-content: flex-start;
    text-align: center;
    flex-direction: column;
  }

  &.black-friday-promo {
    height: unset;

    @media (max-width: 1024px) {
      padding: 2.5rem 2.5rem 1rem;
    }
    @media (max-width: 768px) {
      padding: 0;
    }
    .mobile-promo-image {
      background: radial-gradient(circle at 0 100%, #d5f580 5%, transparent 50%),
                  radial-gradient(circle at 100% 100%, #d5f580 5%, transparent 50%), #98cc04;
      position: relative;
      border-radius: 2rem;
      border: 2px solid #000;
      @media (min-width: 1025px) {
        display: none;
      }
      .image {
        display: flex;
        justify-content: center;
      }
    }
    .asterisk-text {
      margin: 1.5rem 0;
      @media (min-width: 1025px) {
        display: none;
      }
      p {
        font-size: 0.75rem;
        line-height: normal;
      }
    }
  }

  .internet-header-title {
    margin-top: 5rem;
    margin-right: 4rem;
    min-width: 35%;

    @media (min-width: 769px) and (max-width: 1024px){
      margin: 0.5rem 0 3rem;
    }

    @media (max-width: 768px) {
      margin: 2.75rem 0 2.5rem;
    }

    h1 {
      font-size: 3rem;
      line-height: 3.5rem;
      max-width: 30rem;
      text-align: left;

      @media (min-width: 769px) and (max-width: 1024px){
        margin: 0.5rem 0 1.25rem;
        max-width: 100%;
      }

      @media (max-width: 768px) {
        font-size: 2.125rem;
        line-height: 2.5rem;
        margin: 0 0.625rem;
        text-align: left;
        max-width: 100%;
      }

      p {
        font-size: 3rem;
        line-height: 3.5rem;
        font-family: "WalsheimBold";

        @media (min-width: 769px) and (max-width: 1024px){
          font-size: 2.5rem;
          line-height: 3rem;
        }

        @media (max-width: 768px) {
          font-size: 2.125rem;
          line-height: 2.5rem;
          text-align: left;
        }
      }
    }
  }
  .internet-header-subtitle {
    margin-top: 1rem;
    max-width: 17rem;

    @media (min-width: 769px) and (max-width: 1024px){
      max-width: 100%;
    }

    @media (max-width: 768px) {
      max-width: 100%;
    }

    p {
      font-size: 1.5rem;
      line-height: 2rem;

      @media (min-width: 769px) and (max-width: 1024px){
        font-size: 1.25rem;
        line-height: 1.75rem;
        max-width: 100%;
      }

      @media (max-width: 768px) {
        font-size: 1.25rem;
        line-height: 1.75rem;
        margin: 0 0.625rem;
        text-align: left;
      }
    }
  }
  .internet-banner-image {
    min-width: 60%;
    position: relative;
    .main-image {
      height: 28rem;
      width: 100%;
      background-size: cover;
      border-radius: 1rem;
      background-position: left 55% top 50%;

      @media (min-width: 769px) and (max-width: 1024px){
        height: 24rem;
      }

      @media (max-width: 768px) {
        height: 17rem;
      }

      @media (max-width: 553px) {
        height: 14rem;
      }
      img {
        border-radius: 1rem;
      }
      &.black-friday-promo {
        @media (max-width: 1024px) {
          display: none;
        }
      }
      .asterisk-text {
        color: #fff;
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: center;
        margin: unset;
        p {
          font-size: 0.75rem;
          line-height: normal;
          color: #a1a1a1;
          text-align: center;
          padding: 0 1rem 1rem 9rem;
        }
      }
    }
    .overlay-image {
      position: absolute;
      bottom: -4.5rem;
      left: -5.5rem;

      @media (min-width: 769px) and (max-width: 1024px){
        bottom: -3.5rem;
        left: -4.5rem;
      }

      @media (min-width: 554px) and (max-width: 768px) {
        bottom: -4.5rem;
        left: 36%;
      }
      @media (max-width: 553px) {
        left: 30%;
      }
      &.black-friday-promo {
        @media (max-width: 1024px){
          display: none;
        }
      }
      img {
        width: 13.65rem;
        height: auto;

        @media (min-width: 769px) and (max-width: 1024px){
          width: 10.625rem;
          height: auto;
        }

        @media (max-width: 768px) {
          width: 9rem;
          height: auto;
        }
      }
    }
  }
}

.internet-badges-container {
  display: flex;
  justify-content: space-between;
  margin: 8rem auto;

  @media (min-width: 769px) and (max-width: 1024px) {
    flex-wrap: wrap;
    margin: 5rem auto;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    margin: 3rem auto;

    div {
      margin-bottom: 40px;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 769px) and (max-width: 1024px) {
      width: 50%;
      margin-bottom: 3rem;
    }

    p {
      margin-top: 20px;
      text-align: center;
      font-size: 24px;
    }
  }
}

.internet-select-container {
  margin: auto;

  @media screen and (max-width: 768px) {
    .single-mobile-button {
      flex: 1;
      width: 100%;
      text-align: center;
      button {
        width: 100%;
        max-width: 21.25rem;
        margin-left: 0.2rem;
      }
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  .internet-title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 40px 0;
    text-align: center;

    h2 {
      font-size: 3rem;
      line-height: 3.5rem;

      @media (max-width: 25rem) {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }

    .internet-sub-title {
      font-size: 1.5rem;
      line-height: 2rem;
      margin: 2.5rem 0 3.625rem;

      @media (max-width: 768px) {
        margin: 1.25rem 0 8.875rem;
      }

      @media (max-width: 480px) {
        font-size: 1rem;
        line-height: 1.625rem;
        margin: 1.25rem 0 2rem;
      }
    }

    .internet-sale-package-multi {
      display: flex;
      width: 100%;
      justify-content: space-evenly;
      text-align: left;

      @media (max-width: 1024px) {
        flex-direction: column;
        align-items: center;
      }
    }
  }

  .internet-select-badges {
    display: flex;
    justify-content: space-evenly;

    @media screen and (max-width: 768px) {
      align-items: center;
      flex-direction: column;
      margin-top: 2.5rem;
      margin-bottom: 6.25rem;
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 100px 0 1.25rem;

      @media (max-width: 768px) {
        margin: 1.25rem 0;
      }

      p {
        margin-top: 1.25rem;
        text-align: center;
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }
  }
}

.internet-marquee-container {
  background: $light_purple;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3.125rem 0;
  max-width: 100%;
  margin: 4rem auto 4rem;
  z-index: 0;
  min-height: 8.75rem;
  @media (max-width: 768px) {
    padding: 3.125rem 0 0;
  }

  .internet-marquee-text-container {
    padding: 0 1.5rem 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 40rem;
    margin: auto;
    position: relative;
    z-index: 1;

    h3 {
      font-size: 3rem;
      line-height: 3.5rem;
      text-align: center;
      padding-bottom: 2rem;
    }

    p {
      font-size: 1.5rem;
      line-height: 2rem;
      text-align: center;
      padding-bottom: 2rem;

      @media screen and (max-width: 768px) {
        text-align: center;
        font-size: 1.5rem;
        line-height: 2rem;

        br {
          display: none;
        }
      }
    }
  }
  .internet-marquee-image-container {
    display: flex;
    align-items: center;
    max-width: 30rem;
    position: relative;
    margin: 9rem auto 4.5rem;
    z-index: 0;
    @media (max-width: 768px) {
      display: flex;
      align-items: center;
      max-width: 30rem;
      position: relative;
      margin: 0 auto 0;
      z-index: 0;
      bottom: -1.5rem;
    }
    img {
      position: absolute;
      @media (max-width: 768px) {
        position: relative;
      }
    }
  }
}

.internet-mislead-container {
  background: $blue;
  padding-top: 1.875rem;
  width: 100%;
  height: 25rem;
  margin-top: 9.375rem;

  @media (max-width: 1024px) {
    justify-content: center;
    flex-direction: column;
    height: 21rem;
  }

  .internet-mislead-content-container {
    display: flex;
    background: #e8f4eb;
    justify-content: space-between;
    align-items: end;
    height: 21rem;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .internet-mislead-image img {
      vertical-align: bottom;
    }
  }

  @media (max-width: 768px) {
    justify-content: center;
    flex-direction: column;

    .internet-mislead-image {
      display: none;
    }

    .internet-mislead-mobile-container {
      display: flex;
      width: 400px;
      justify-content: space-between;
    }
  }

  h2 {
    p {
      text-align: center;
      padding-bottom: 40px;
      font-size: 48px;
      line-height: 56px;
      font-family: "WalsheimBold", serif;
    }

    @media (max-width: 480px) {
      font-size: 32px;
      line-height: 40px;
    }
  }

  .internet-mislead-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    align-self: center;

    @media (min-width: 769px) and (max-width: 1024px) {
      width: 60%;
    }

    h2,
    p {
      font-size: 3rem;
      line-height: 3.5rem;
      font-family: "WalsheimBold", serif;
      text-align: center;
      margin-bottom: 2rem;

      @media (max-width: 1024px) {
        margin-bottom: 1.25rem;
      }

      @media screen and (max-width: 768px) {
        font-size: 2rem;
        line-height: 2.5rem;
      }
    }
    .page-link-container {
      @media screen and (max-width: 768px) {
        margin-bottom: 3.125rem;
      }
    }
  }

  .internet-mislead-mobile-container {
    display: none;

    .internet-mislead-image img {
      vertical-align: bottom;
    }

    @media (max-width: 768px) {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }

    @media (max-width: 480px) {
      width: 100%;
    }
  }
}

.internet-provider-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 7rem 0;

  @media (max-width: 480px) {
    margin: 4.75rem 0 3rem;
    width: 100%;
  }
  .internet-provider-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 480px) {
      margin: 0 0.5rem;
    }
  }

  h2 {
    font-size: 48px;
    line-height: 56px;

    @media (max-width: 768px) {
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      margin: 0 40px;
    }

    @media (max-width: 480px) {
      margin: 0;
    }
  }

  p {
    font-size: 20px;
    line-height: 26px;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    text-align: center;
    width: 450px;

    @media (max-width: 768px) {
      font-size: 20px;
      line-height: 26px;
    }

    @media (max-width: 480px) {
      width: 90%;
    }
  }

  button {
    width: 100%;
    max-width: 18.75rem;
  }
}
.internet-review-container {
  .internet-review-headers {
    text-align: center;
    margin-bottom: 50px;

    h2 {
      font-size: 3rem;
      line-height: 3.5rem;
      margin-bottom: 0.625rem;
    }
    p {
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }
}
.internet-legal-tagline-container {
  .szh-accordion__item {
    border-bottom: none;
    width: 100%;
    padding-bottom: 1rem;
    margin: 1rem 0.125rem 0;
  }
}
.internet-package-container {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: row;
  margin: 5.5rem 0;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
  }

  .internet-package {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 0.625rem;
    max-width: 36.25rem;
    max-height: 17.5rem;
    border: 2px solid $black;
    border-radius: 1rem;
    overflow: hidden;

    @media (max-width: 48rem) {
      flex-direction: column;
      margin: 0 20px 20px;
      max-width: 21.375rem;
      max-height: 23.75rem;
      height: 23.75rem;
    }

    h3 {
      font-size: 32px;
      line-height: 40px;

      p {
        font-size: 32px;
        line-height: 40px;
      }
    }

    p {
      font-size: 16px;
      line-height: 22px;
    }

    img {
      width: 215px;
      height: 250px;

      @media screen and (max-width: 48rem) {
        width: 9.6875rem;
        height: 11.25rem;
        margin-bottom: 1.75rem;
      }
    }
    .internet-package-text {
      display: flex;
      flex: 1;
      height: 100%;
      flex-direction: column;
      justify-content: space-evenly;
      max-height: 200px;
      padding: 0 20px;

      @media screen and (max-width: 48rem) {
        justify-content: flex-start;
        h3 {
          margin-top: 1.5625rem;
          margin-bottom: 12px;
        }
        p {
          margin-bottom: 10px;
        }
      }
    }
  }

  .internet-package:nth-of-type(1) {
    @media (max-width: 1000px) {
      margin-bottom: 40px;
    }
  }
}
.internet-availability-map-container {
  background: $light_purple;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  margin: 4rem auto 4rem;
  z-index: 0;
  min-height: 8.75rem;
  @media (max-width: 768px) {
    padding: 3.125rem 0 0;
  }
  .internet-availability-map-content {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    padding: 0 2.5rem;

    @media (min-width: 769px) and (max-width: 1024px) {
      font-size: 2rem;
      line-height: 2.5rem;
      align-items: flex-end;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      margin: 0;
      width: 100%;
      padding: 0;
    }

    .left-content {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      width: 50%;
      padding-top: 3rem;

      @media (max-width: 768px) {
        padding: 0;
        width: 100%;
      }

      h2 {
        font-size: 3rem;
        line-height: 3.5rem;
        max-width: 24.5rem;
        padding-bottom: 1rem;
        text-align: left;
        @media (max-width: 1024px) {
          font-size: 2rem;
          line-height: 2.5rem;
        }
      }

      p {
        font-size: 1.25rem;
        line-height: 1.625rem;
        max-width: 27.5rem;
        padding-bottom: 2.5rem;
        @media (min-width: 769px) and (max-width: 1024px) {
          font-size: 1rem;
          padding-bottom: 1.5rem;
          max-width: 18rem;
        }
        @media (max-width: 768px) {
          max-width: 100%;
        }
      }
      .page-link-container {
        padding-bottom: 2rem;
      }
    }
    .right-content {
      width: 50%;
      position: relative;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
}

.internet-review-container {
  margin: 60px 0;
}
.internet-speeds-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 3rem;
  padding: 2.5rem 1rem;
  @media (max-width: 48rem) {
    padding: 2.5rem 0;
  }
  .internet-speeds-header {
    h2 {
      font-size: 3rem;
      line-height: 3.5rem;
      font-weight: 700;
      letter-spacing: -0.01em;
      text-align: center;
      @media (max-width: 25rem) {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }
  }
  .internet-speeds-inner {
    display: flex;
    column-gap: 1rem;
    &.flash-sale {
      @media (min-width: 1025px) and (max-width: 1300px) {
        flex-wrap: wrap;
        max-width: 50rem;
      }
    }
    @media (max-width: 1024px) {
      flex-direction: column;
      row-gap: 2rem;
    }
  }
}
